import Swiper from "swiper/bundle";
import "swiper/css/bundle";

jQuery(document).ready(function ($) {
    function getUrlVars() {
        var vars = [],
            hash;
        var hashes = window.location.href
            .slice(window.location.href.indexOf("?") + 1)
            .split("&");
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split("=");
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    var param = getUrlVars()["slide"];

    if (param == null) {
        param = 0;
    }

    //getting localised script
    var instructions = fields_js.stages_to_skip;

    // CONFIGURATOR SLIDER

    // const wheelThumbSwiper = new Swiper("#wheelthumbSlider", {
    //     spaceBetween: 10,
    // 	speed: 1000,
    //     slidesPerView: 4,
    //     //watchSlidesProgress: true,
    // });

    // const wheelswiper = new Swiper("#wheelSlider", {
    // 	speed: 1000,
    // 	thumbs: {
    // 		swiper: wheelThumbSwiper,
    // 	},
    // 	pagination: {
    // 		el: '.wheels-pagination',
    // 		clickable: true,
    // 		renderBullet: function (index, className) {
    // 			var img = $('#wheelSlider .swiper-slide').eq(index).attr('data-bullet');
    // 			var title = $('#wheelSlider .swiper-slide').eq(index).attr('data-name');
    // 			return '<span class="'+className+'"><img src="'+img+'" alt="" /><div class="nameTitle">'+title+'</div></span>';
    // 		}
    // 	},
    // });

    if (
        $("section.bikeConfigurator").length > 0 ||
        $("section.configurator-summary").length > 0
    ) {
        //multiple sliders
        const newWheelThumbSwiper = document.querySelectorAll(
            ".wheel-thumb-wrapper"
        );
        const newWheelSlider = document.querySelectorAll(".wheel-wrapper");

        var wheelThumbSwiper = {};
        var wheelswiper = {};

        var i = 0;

        for (i = 0; i < newWheelSlider.length; i++) {
            var second = newWheelThumbSwiper[i].nextElementSibling;
            var third = second.nextElementSibling;

            newWheelSlider[i].classList.add("wheel-wrapper-" + i); //slider
            newWheelThumbSwiper[i].classList.add("wheel-thumb-wrapper-" + i); //slider thumb

            second.classList.add("thumbSlider-button-next-" + i); //next
            third.classList.add("thumbSlider-button-prev-" + i); //previous

            wheelThumbSwiper[i] = new Swiper(".wheel-thumb-wrapper-" + i, {
                spaceBetween: 10,
                speed: 1000,
                slidesPerView: 5.5,
                direction: "horizontal",
                loop: false,
                autoplay: false,
                navigation: {
                    nextEl: ".thumbSlider-button-next-" + i,
                    prevEl: ".thumbSlider-button-prev-" + i,
                },
                breakpoints: {
                    768: {
                        slidesPerView: 5.5,
                        spaceBetween: 10,
                    },
                    0: {
                        slidesPerView: 3.3,
                        spaceBetween: 10,
                    },
                },
            });

            wheelswiper[i] = new Swiper(".wheel-wrapper-" + i, {
                speed: 1000,
                centeredSlides: true,
                slidesPerView: 1,
                thumbs: {
                    swiper: wheelThumbSwiper[i],
                },
            });

            var slidnumber = $("#wheelsNumberForSlide").val();

            if (
                slidnumber == null ||
                slidnumber == undefined ||
                slidnumber == ""
            ) {
                slidnumber = 0;
            }

            wheelswiper[i].slideTo(slidnumber);
        }

        //bike swiper
        const swiper = new Swiper(".bike-wrapper", {
            speed: 1000,
            slidesPerView: 1,
            direction: "horizontal",
            loop: false,
            noSwiping: true,
            autoplay: false,
            noSwipingClass: "bike-wrapper",
            onlyExternal: true,
            initialSlide: param,

            navigation: {
                nextEl: ".bike-swiper-button-next",
                prevEl: ".bike-swiper-button-prev",
            },
        });

        const brandswiper = new Swiper(".brandSlider", {
            speed: 1200,
            slidesPerView: 1,
            direction: "horizontal",
            loop: false,
            noSwiping: true,
            autoplay: false,
            noSwipingClass: "brandSlider",
            onlyExternal: true,
            initialSlide: param,

            navigation: {
                nextEl: ".bike-swiper-button-next",
                prevEl: ".bike-swiper-button-prev",
            },
        });

        $(".breadcrumb").removeClass("active");
        $(".breadcrumb").each(function (index, value) {
            var slide = param;
            if (index == slide) {
                $(this).addClass("active");
            }
        });

        function submitForm(form) {
            console.log(form);

            var $inputs = $("#" + form + " :input");
            var values = {};
            $inputs.each(function () {
                values[this.name] = $(this).val();
            });

            $.ajax({
                type: "POST",
                url: site_data.adminajax,
                data: {
                    action: "save_ref",
                    post: values,
                },
                dataType: "json",
                encode: true,
            })
                .done(function (response) {})
                .fail(function () {})
                .always(function () {});
        }

        // //share reference ajax call to save
        $("body").on("click", "#BBsave_reference", function (event) {
            event.preventDefault();
            var form = $(this).closest("form").attr("id");
            submitForm(form);
        });

        //breadcrumb class to make bold on click
        $("body").on("click", ".breadcrumb", function (event) {
            $(".breadcrumb").removeClass("active");

            //set active
            $(this).addClass("active");
            event.preventDefault();
            var num = $(this).attr("data-slide");

            //hide infoWindow and show prev and next arrows for slider
            $(".infoWindow").removeClass("open");
            $(".progressIcons .icon").removeClass("white");
            $(".image").hide();
            $(".overview").hide();

            $(".bike-swiper-button-prev").show();
            $(".bike-swiper-button-next").show();

            //move sliders to slide
            brandswiper.slideTo(num);
            swiper.slideTo(num);

            //get slide active colour and price
            var colourName = $(
                ".swiper-slide-active .colourPanel .active"
            ).attr("data-name");
            var colourPrice = $(
                ".swiper-slide-active .colourPanel .active"
            ).attr("data-price");

            //change JSON
            var bikeName = $(this).attr("data-bike");
            var price = $(this).attr("data-price");
            var json = $("#jsonData").val();
            var jsonObj = JSON.parse(json);
            jsonObj.bike.name = bikeName;
            jsonObj.bike.price = price;
            jsonObj.frameset.color.name = colourName;
            jsonObj.frameset.color.price = colourPrice;
            $("#jsonData").val(JSON.stringify(jsonObj));
        });

        //NEXT SLIDE
        $("body").on("click", ".bike-swiper-button-next", function () {
            //get slide active colour and price
            var colourName = $(
                ".swiper-slide-active .colourPanel .active"
            ).attr("data-name");
            var colourPrice = $(
                ".swiper-slide-active .colourPanel .active"
            ).attr("data-price");

            var bikeName = $(".swiper-slide-active").attr("data-bike");
            var price = $(".swiper-slide-active").attr("data-price");
            var nextstep = $(".swiper-slide-active").attr("data-next");

            var json = $("#jsonData").val();
            var jsonObj = JSON.parse(json);
            jsonObj.bike.price = price;
            jsonObj.next = Number(nextstep);
            jsonObj.bike.name = bikeName;

            jsonObj.frameset.color.name = colourName;
            jsonObj.frameset.color.price = colourPrice;
            $("#jsonData").val(JSON.stringify(jsonObj));

            var numItems = $(".breadcrumb").length;
            var numItems = numItems - 1;

            $(".breadcrumb").each(function (index, value) {
                if ($(this).hasClass("active")) {
                    if (index != numItems) {
                        $(this).removeClass("active");
                        $(this).next().next().addClass("active");
                        return false;
                    }
                }
            });
        });

        //PREVIOUS SLIDE
        $("body").on("click", ".bike-swiper-button-prev", function () {
            //get slide active colour and price
            var colourName = $(
                ".swiper-slide-active .colourPanel .active"
            ).attr("data-name");
            var colourPrice = $(
                ".swiper-slide-active .colourPanel .active"
            ).attr("data-price");

            var bikeName = $(".swiper-slide-active").attr("data-bike");
            var price = $(".swiper-slide-active").attr("data-price");
            var nextstep = $(".swiper-slide-active").attr("data-next");

            var json = $("#jsonData").val();
            var jsonObj = JSON.parse(json);
            jsonObj.bike.name = bikeName;
            jsonObj.bike.price = price;
            jsonObj.next = Number(nextstep);
            jsonObj.frameset.color.name = colourName;
            jsonObj.frameset.color.price = colourPrice;
            $("#jsonData").val(JSON.stringify(jsonObj));

            $(".breadcrumb").each(function (index, value) {
                if ($(this).hasClass("active")) {
                    if (index != 0) {
                        $(this).removeClass("active");
                        $(this).prev().prev().addClass("active");
                        return false;
                    }
                }
            });
        });

        //info window
        $("body").on("click", ".info.info_frameset", function (event) {
            event.preventDefault();
            $(".bikeImage .imageContainer img").addClass("moveOver");
            $(".infoWindow").addClass("open");
            $(".progressIcons .icon").addClass("white");
            $(".bike-swiper-button-prev").hide();
            $(".bike-swiper-button-next").hide();
            $(".bikeChoices").hide();

            //get current post
            var post = $(".bikeSwiperWrapperinner .swiper-slide-active").attr(
                "data-post"
            );

            //get post information
            $.ajax({
                type: "POST",
                url: site_data.adminajax,
                data: {
                    action: "information",
                    post: post,
                },
                dataType: "json",
                encode: true,
            })
                .done(function (response) {
                    $(".infoWindow .image").html(response.image);
                    $(".infoWindow .overview").html(response.overview);
                    $(".infoWindow .image").fadeIn();
                    $(".infoWindow .overview").fadeIn();
                })
                .fail(function () {})
                .always(function () {});
        });

        $("body").on("click", ".infoWindow .close", function (event) {
            event.preventDefault();
            $(".bikeImage .imageContainer img").removeClass("moveOver");
            $(".infoWindow").removeClass("open");
            $(".progressIcons .icon").removeClass("white");
            $(".bike-swiper-button-prev").show();
            $(".bike-swiper-button-next").show();
            $(".infoWindow .image").hide();
            $(".infoWindow .overview").hide();
            $(".bikeChoices").show();
        });

        //info window Wheel
        $("body").on("click", ".infoWheel", function (event) {
            event.preventDefault();
            $(".wheelImage .imageContainer img").addClass("moveOver");
            $(".infoWindowWheels").addClass("open");
            $(".progressIcons .icon").addClass("white");

            //get current post
            var post = $(".bikeSwiperWrapperinner .swiper-slide-active").attr(
                "data-post"
            );
            var row = $("#wheelSlider .swiper-slide-thumb-active").attr(
                "data-slide-number"
            );

            //get post information
            $.ajax({
                type: "POST",
                url: site_data.adminajax,
                data: {
                    action: "wheelinformation",
                    post: post,
                    row: row,
                },
                dataType: "json",
                encode: true,
            })
                .done(function (response) {
                    $(".infoWindowWheels .image").html(response.image);
                    $(".infoWindowWheels .title").html(
                        "<h2>" + response.title + "</h2>"
                    );
                    $(".infoWindowWheels .overview").html(response.overview);
                    $(".infoWindowWheels .image").fadeIn();
                    $(".infoWindowWheels .title").fadeIn();
                    $(".infoWindowWheels .overview").fadeIn();
                })
                .fail(function () {})
                .always(function () {});
        });

        $("body").on("click", ".infoWindowWheels .close", function (event) {
            event.preventDefault();
            $(".wheelImage .imageContainer img").removeClass("moveOver");
            $(".infoWindowWheels").removeClass("open");
            $(".progressIcons .icon").removeClass("white");

            $(".infoWindowWheels .image").hide();
            $(".infoWindowWheels .overview").hide();
            $(".infoWindowWheels .title").hide();
        });

        //info window Overlay
        $("body").on("click", ".infoOverlay", function (event) {
            event.preventDefault();

            $(".infoWindowOverlay").toggleClass("open");
            $(".progressIcons .icon").toggleClass("white");

            //get current post
            var post = $(".bikeSwiperWrapperinner .swiper-slide-active").attr(
                "data-post"
            );
            var type = $(this).attr("data-info");

            //get post information
            $.ajax({
                type: "POST",
                url: site_data.adminajax,
                data: {
                    action: "overlayinformation",
                    post: post,
                    type: type,
                },
                dataType: "json",
                encode: true,
            })
                .done(function (response) {
                    $(".infoWindowOverlay .title").html(
                        "<h2>" + response.title + "</h2>"
                    );
                    $(".infoWindowOverlay .overview").html(response.overview);

                    $(".infoWindowOverlay .title").fadeIn();
                    $(".infoWindowOverlay .overview").fadeIn();
                })
                .fail(function () {})
                .always(function () {});
        });

        $("body").on("click", ".infoWindowOverlay .close", function (event) {
            event.preventDefault();

            $(".infoWindowOverlay").removeClass("open");
            $(".progressIcons .icon").removeClass("white");

            $(".infoWindowOverlay .image").hide();
            $(".infoWindowOverlay .overview").hide();
            $(".infoWindowOverlay .title").hide();
        });

        //colour wheel AJAX
        $("body").on("click", ".colourWheel", function (event) {
            event.preventDefault();
            var name = $(this).attr("data-name");
            var price = $(this).attr("data-price");
            var imageID = $(this).attr("data-imageID");
            var postID = $(this).attr("data-postID");

            var json = $("#jsonData").val();
            var jsonObj = JSON.parse(json);
            jsonObj.frameset.color.name = name;
            jsonObj.frameset.color.price = price;
            $("#jsonData").val(JSON.stringify(jsonObj));

            //change active state on colourwheel
            $(".swiper-slide-active .colourWheel").removeClass("active");
            $(this).addClass("active");

            $(this).parent().find(".colorName").html(name);

            if (
                name == "Prisma Studio" ||
                name == "prisma studio" ||
                name == "Custom Paint"
            ) {
                $(this).parent().next().next().addClass("visible");
                $(".prismaModal").addClass("visible");
            } else {
                $(".bike" + postID + " .imageContainer").fadeOut();
                $(this).parent().next().next().removeClass("visible");
                $(".prismaModal").removeClass("visible");

                $.ajax({
                    type: "POST",
                    url: site_data.adminajax,
                    data: {
                        action: "change_color",
                        imageID: imageID,
                        name: name,
                    },
                    dataType: "json",
                    encode: true,
                })
                    .done(function (response) {
                        //console.log(response.image);
                        $(".bike" + postID + " .imageContainer").html(
                            response.image
                        );
                        $(".bike" + postID + " .imageContainer").fadeIn();
                    })
                    .fail(function () {})
                    .always(function () {});
            }
        });

        //clicking next and passing stage
        var called = "";

        $("body").on("click", ".nextButton, .unsure", function (event) {
            event.preventDefault();

            if ($(".infoWindowOverlay").hasClass("open")) {
                $(".infoWindowOverlay").removeClass("open");
                $(".progressIcons .icon").removeClass("white");
                $(".infoWindowOverlay .image").hide();
                $(".infoWindowOverlay .overview").hide();
                $(".infoWindowOverlay .title").hide();
            }

            if ($(".infoWindowWheels").hasClass("open")) {
                $(".progressIcons .icon").removeClass("white");
            }

            if ($(this).hasClass("disabled")) {
                return false;
            }

            $(".nextButton").addClass("disabled");

            var post = $(".bikeConfigSlide.swiper-slide-active").attr(
                "data-post"
            );

            var json = $("#jsonData").val();
            var jsonObj = JSON.parse(json);

            var slidenum = $(".bikeConfigSlide.swiper-slide-active").attr(
                "data-slide"
            );
            var contin = true;

            var skipping = "";

            $.each(instructions, function (key, val) {
                if (post == val.bike_id) {
                    skipping = val.skip_stages.toString();
                }
            });

            var currentsteps = jsonObj.current;
            var nextstep = jsonObj.next;

            if (currentsteps < 3) {
                called = false;
            }

            //ajax call to get wheel slider images
            if (currentsteps > 2 && currentsteps < 6) {
                //populate slider with slides AJAX call

                //get wheels based on brakes

                var brakes = jsonObj["brakes"].name;
                var selectedWheel = jsonObj["wheels"].name;

                if (called != true) {
                    $.each(wheelswiper, function (index, value) {
                        wheelswiper[index].removeAllSlides();
                        wheelThumbSwiper[index].removeAllSlides();
                    });

                    //call wheel function to add images to sliders
                    newWheelsFunction(post, "all", brakes, selectedWheel);
                }

                called = true;
            }

            var skipArray = skipping.split(",");

            if (contin == true) {
                var steps = Number(currentsteps);

                //check if in array
                if ($.inArray(steps, jsonObj.steps) != -1) {
                    //in array
                } else {
                    //not in array
                    jsonObj.steps.push(steps);
                }

                jsonObj.slide = Number(slidenum);
                jsonObj.post = Number(post);

                // jsonObj.current = Number(nextstep);
                //jsonObj.next = Number(nextstep) + 1;

                //put answer in json

                if ($(this).hasClass("unsure")) {
                    var nameAnswer = "Not Sure";
                    var priceAnswer = 0;
                    var objectName = $(this).attr("data-object");
                    jsonObj[objectName].name = nameAnswer;
                    jsonObj[objectName].price = priceAnswer;
                } else {
                    if (currentsteps > 0) {
                        if (currentsteps != 5) {
                            $(".breadcrumbSlideWheel").removeClass("active");
                            $(
                                '.breadcrumbSlideWheel[data-control="0"'
                            ).addClass("active");
                        }

                        if (currentsteps == 5) {
                            //wheels
                            var rowAnswer = $(
                                ".wheel-wrapper .active .swiper-slide-active"
                            ).attr("data-slide-number");
                            var nameAnswer = $(
                                ".wheel-wrapper .active .swiper-slide-active"
                            ).attr("data-name");
                            var priceAnswer = $(
                                ".wheel-wrapper .active .swiper-slide-active"
                            ).attr("data-price");
                            jsonObj["wheels"].name = nameAnswer;
                            jsonObj["wheels"].price = priceAnswer;
                            jsonObj["wheels"].wheelsNumber = rowAnswer;
                        } else if (currentsteps == 6) {
                            //after wheels
                            var nameAnswer = $(
                                ".select-wrapper select option:selected"
                            ).val();
                            var priceAnswer = $(
                                ".select-wrapper select option:selected"
                            ).attr("data-price");
                            var objectName = $(".select-wrapper select").attr(
                                "data-object"
                            );
                            jsonObj[objectName].name = nameAnswer;
                            jsonObj[objectName].price = priceAnswer;
                        } else {
                            //before wheels
                            var objectName = $(".active input:checked").attr(
                                "data-object"
                            );
                            var nameAnswer = $(".active input:checked").attr(
                                "data-name"
                            );
                            var priceAnswer = $(".active input:checked").attr(
                                "data-price"
                            );

                            jsonObj[objectName].name = nameAnswer;
                            jsonObj[objectName].price = priceAnswer;
                        }
                    }
                }

                var skipCount = 0;
                if ($.inArray(nextstep.toString(), skipArray) >= 0) {
                    //value exists
                    // while in array keep adding 1 until no longer in array
                    while ($.inArray(nextstep.toString(), skipArray) >= 0) {
                        skipCount++;
                        nextstep = Number(nextstep) + 1;
                        jsonObj.next = Number(nextstep) + 1;
                        jsonObj.current = Number(nextstep);
                        if ($.inArray(nextstep.toString(), skipArray) == -1) {
                            break;
                        }
                    }
                } else {
                    if (nextstep == "") {
                        nextstep = Number(nextstep) + 1;
                    }
                    jsonObj.next = Number(nextstep) + 1;
                    jsonObj.current = Number(nextstep);
                }

                $(".previousBtn").fadeIn("slow");
                $(".prevButton").fadeIn("slow");

                if (nextstep == 1) {
                    var value = jsonObj.framesize.name;
                    $(".infoWindow .ajaxResponse").empty();
                    stageFunction(post, "framesize", nextstep, value);
                }

                if (nextstep == 2) {
                    var value = jsonObj.brakes.name;
                    //post , stage , nextstep , value
                    stageFunction(post, "brakes", nextstep, value);
                }

                if (nextstep == 3) {
                    var value = jsonObj.barstem.name;
                    stageFunction(post, "barstem", nextstep, value);
                }

                if (nextstep == 4) {
                    var value = jsonObj.seatpost.name;
                    stageFunction(post, "seatpost", nextstep, value);
                }

                if (nextstep == 5) {
                    $(".infoWindow").removeClass("open");
                    $(".progressIcons .icon").removeClass("white");
                    $(".bike-swiper-button-prev").hide();
                    $(".bike-swiper-button-next").hide();
                    $(".wheel-wrapper-0").fadeIn("slow");
                    $(".wheel-thumb-wrapper-0").fadeIn("slow");
                    $(".wheelWrapper").fadeIn("slow");
                    $(".thumbSlider-button-next-0").fadeIn("slow");
                    $(".thumbSlider-button-prev-0").fadeIn("slow");
                    $(".bikeSwiperWrapperinner").fadeOut("slow");
                    $("#breadNav").fadeIn("slow");
                    $(".brandContainer").hide();
                    $(".infoWheel").fadeIn("slow");
                    $(".nextButton").removeClass("disabled");

                    $(".wheelWrapper").addClass("activated");

                    $(".wheelWrapper").fadeIn("slow", function () {});
                } else {
                    $(".infoWindowWheels").removeClass("open");
                    $(".infoWindowWheels .image").hide();
                    $(".infoWindowWheels .overview").hide();
                    $(".infoWindowWheels .title").hide();
                    $(".wheelImage .imageContainer img").removeClass(
                        "moveOver"
                    );

                    $(".wheelWrapper").fadeOut("slow", function () {
                        $(".wheelWrapper").removeClass("activated");
                    });
                }

                if (currentsteps == 5 && nextstep >= 6) {
                    //hide wheel section
                    $(".wheel-thumb-wrapper").fadeOut("slow");
                    $(".thumbSlider-button-next").fadeOut("slow");
                    $(".thumbSlider-button-prev").fadeOut("slow");
                    $(".infoWheel").fadeOut("slow");
                    $("#breadNav").fadeOut("slow");
                    $(".wheel-wrapper").fadeOut("slow", function () {
                        $(".groupContainer").fadeIn("slow");
                    });
                }

                if (nextstep == 5) {
                    changeStageFunction("WHEELS", "3");
                }

                if (nextstep == 6) {
                    fullwidthStageFunction(post, "groupset", nextstep);
                }

                if (nextstep == 7) {
                    var groupset = jsonObj["groupset"].name;
                    var value = jsonObj.power_meter.name;
                    fullwidthStageFunction(
                        post,
                        "power_meter",
                        nextstep,
                        groupset,
                        value
                    );
                }

                if (nextstep == 8) {
                    var value = jsonObj.crank_length.name;
                    fullwidthStageFunction(
                        post,
                        "crank_length",
                        nextstep,
                        null,
                        value
                    );
                }

                if (nextstep == 9) {
                    var groupset = jsonObj["groupset"].name;
                    var value = jsonObj.chainset.name;
                    fullwidthStageFunction(
                        post,
                        "chainset",
                        nextstep,
                        groupset,
                        value
                    );
                }

                if (nextstep == 10) {
                    var groupset = jsonObj["groupset"].name;
                    var value = jsonObj.cassette.name;
                    fullwidthStageFunction(
                        post,
                        "cassette",
                        nextstep,
                        groupset,
                        value
                    );
                }

                if (nextstep == 11) {
                    var value = jsonObj.tyre.name;
                    fullwidthStageFunction(post, "tyre", nextstep, null, value);
                }

                if (nextstep == 12) {
                    var value = jsonObj.saddle.name;
                    fullwidthStageFunction(
                        post,
                        "saddle",
                        nextstep,
                        null,
                        value
                    );
                }

                if (nextstep == 13) {
                    var value = jsonObj.ceramicspeed.name;
                    fullwidthStageFunction(
                        post,
                        "ceramicspeed",
                        nextstep,
                        null,
                        value
                    );
                }

                if (nextstep == 14) {
                    var value = jsonObj.bottle_cage.name;
                    fullwidthStageFunction(
                        post,
                        "bottle_cage",
                        nextstep,
                        null,
                        value
                    );
                }

                if (nextstep == 15) {
                    var value = jsonObj.hammerhead.name;
                    fullwidthStageFunction(
                        post,
                        "hammerhead",
                        nextstep,
                        null,
                        value
                    );
                }

                if (nextstep == 16) {
                    var value = jsonObj.bike_fit.name;
                    fullwidthStageFunction(
                        post,
                        "bike_fit",
                        nextstep,
                        null,
                        value
                    );
                }

                if (nextstep == 17) {
                    var value = jsonObj.delivery.name;
                    fullwidthStageFunction(
                        post,
                        "delivery",
                        nextstep,
                        null,
                        value
                    );
                }

                if (nextstep == 18) {
                    var value = jsonObj.scheme.name;
                    fullwidthStageFunction(
                        post,
                        "scheme",
                        nextstep,
                        null,
                        value
                    );
                }

                if (skipCount > 0) {
                    var newNext = Number(nextstep) - Number(skipCount);

                    if (nextstep == 19 || jsonObj.stagesubmit == newNext) {
                        submitFunction(JSON.stringify(jsonObj));
                    } else {
                        $("#jsonData").val(JSON.stringify(jsonObj));
                    }
                } else {
                    if (nextstep == 19 || jsonObj.stagesubmit == nextstep) {
                        submitFunction(JSON.stringify(jsonObj));
                    } else {
                        $("#jsonData").val(JSON.stringify(jsonObj));
                    }
                }
            }
        });

        function submitFunction(data) {
            $("#jsonData").val(data).trigger("change");
        }

        $("body").on("change", "#jsonData", function (event) {
            $("#configuratorForm").submit();
        });

        //clicking prev and passing stage
        $("body").on("click", ".prevButton", function (event) {
            event.preventDefault();

            if ($(".infoWindowOverlay").hasClass("open")) {
                $(".infoWindowOverlay").removeClass("open");
                $(".progressIcons .icon").removeClass("white");
                $(".infoWindowOverlay .image").hide();
                $(".infoWindowOverlay .overview").hide();
                $(".infoWindowOverlay .title").hide();
            }
            if ($(".infoWindowWheels").hasClass("open")) {
                $(".progressIcons .icon").removeClass("white");
            }

            //const urlParams = new URLSearchParams(location.search);

            var json = $("#jsonData").val();
            var jsonObj = JSON.parse(json);
            var currentsteps = jsonObj.current;

            //get last element in array
            var prev = $(jsonObj.steps).get(-1);

            //go to first step
            if (prev == 0) {
                $(".infoWindow").removeClass("open");
                $(".progressIcons .icon").removeClass("white");
                $(".bikeImage .imageContainer img").removeClass("moveOver");
                $(".bike-swiper-button-prev").fadeIn();
                $(".bike-swiper-button-next").fadeIn();
                $(".colourPanel").fadeIn();
                $(".bikeChoices").fadeIn();
                $(".info").fadeIn();
                $(".close").fadeIn();

                $(".prev .previousBtn").hide();
                $(".prev .bikeChoices").fadeIn();

                $(".ajaxResponse").fadeOut();

                $(".infoWindow .brakes").fadeOut("open", function () {
                    $(".infoWindow .close").fadeIn();
                    $(".brakesResponse").fadeOut();
                });
            }

            if (currentsteps > 5) {
                $(".breadcrumbSlideWheel").removeClass("active");
                $('.breadcrumbSlideWheel[data-control="0"').addClass("active");
            }

            if (currentsteps == 5) {
                $(".infoWindow").addClass("open");
                $(".progressIcons .icon").addClass("white");
                $(".bike-swiper-button-prev").show();
                $(".bike-swiper-button-next").show();
                $(".wheel-wrapper").fadeOut("slow");
                $(".bikeSwiperWrapperinner").fadeIn("slow");
                $(".wheel-thumb-wrapper").fadeOut("slow");
                $(".thumbSlider-button-next").fadeOut("slow");
                $(".thumbSlider-button-prev").fadeOut("slow");
                $("#breadNav").fadeOut("slow");
                $(".brandContainer").show();
                $(".infoWheel").fadeOut("slow");

                //wheel info window
                $(".infoWindowWheels").removeClass("open");
                $(".wheelImage .imageContainer img").removeClass("moveOver");
                $(".infoWindowWheels .image").hide();
                $(".infoWindowWheels .overview").hide();
                $(".infoWindowWheels .title").hide();
            }

            if (location.search != "") {
                if (jsonObj.prevHide == prev) {
                    $(".prevButton").fadeOut("slow");
                }
            }
            if (prev == 1) {
                var div = "framesize";
                previousFunction(div, currentsteps);
            }
            if (prev == 2) {
                var div = "brakes";
                previousFunction(div, currentsteps);
            }
            if (prev == 3) {
                var div = "barstem";
                previousFunction(div, currentsteps);
            }
            if (prev == 4) {
                var div = "seatpost";
                previousFunction(div, currentsteps);
            }
            if (prev == 5) {
                $(".wheel-wrapper .swiper-wrapper").removeClass("active");
                $(".wheel-wrapper-0 .swiper-wrapper").addClass("active");
                $('.wheel-wrapper:not(".wheel-wrapper-0")').hide();

                $(".infoWheel").fadeIn("slow");
                $("#breadNav").fadeIn("slow");
                $(".groupContainer").fadeOut("slow", function () {
                    $(".wheel-wrapper-0").fadeIn("slow");
                    $(".wheel-thumb-wrapper-0").fadeIn("slow");
                    $(".thumbSlider-button-next-0").fadeIn("slow");
                    $(".thumbSlider-button-prev-0").fadeIn("slow");
                });

                $(".wheelWrapper").fadeIn("slow", function () {
                    $(".wheelWrapper").addClass("activated");
                });
            } else {
                $(".wheelWrapper").fadeOut("slow", function () {
                    $(".wheelWrapper").removeClass("activated");
                });
            }

            if (prev == 5 || prev == 6) {
                var div = "groupset";
                previousFunction(div, currentsteps);
            }

            if (prev == 7) {
                var div = "power_meter";
                previousFunction(div, currentsteps);
            }

            if (prev == 8) {
                var div = "crank_length";
                previousFunction(div, currentsteps);
            }

            if (prev == 9) {
                var div = "chainset";
                previousFunction(div, currentsteps);
            }

            if (prev == 10) {
                var div = "cassette";
                previousFunction(div, currentsteps);
            }

            if (prev == 11) {
                var div = "tyre";
                previousFunction(div, currentsteps);
            }

            if (prev == 12) {
                var div = "saddle";
                previousFunction(div, currentsteps);
            }

            if (prev == 13) {
                var div = "ceramicspeed";
                previousFunction(div, currentsteps);
            }

            if (prev == 14) {
                var div = "bottle_cage";
                previousFunction(div, currentsteps);
            }

            if (prev == 15) {
                var div = "hammerhead";
                previousFunction(div, currentsteps);
            }

            if (prev == 16) {
                var div = "bike_fit";
                previousFunction(div, currentsteps);
            }

            if (prev == 17) {
                var div = "delivery";
                previousFunction(div, currentsteps);
            }

            if (prev == 18) {
                var div = "scheme";
                previousFunction(div, currentsteps);
            }

            jsonObj.steps.pop();

            // if (jsonObj.steps.length === 0) {

            if (jsonObj.current == 0) {
                var nextstep = $(".bikeConfigSlide.swiper-slide-active").attr(
                    "data-next"
                );
                jsonObj.next = nextstep;
                $(".ajaxResponse .active").remove();
            } else {
                jsonObj.next = Number(currentsteps);
            }

            jsonObj.current = prev;

            $("#jsonData").val(JSON.stringify(jsonObj));
        });

        //previous stage
        function previousFunction(div, current) {
            if (current < 5) {
                $(".infoWindow .ajaxResponse").fadeOut(function () {
                    $(".infoWindow .ajaxResponse .active").remove();
                    // $('.ajaxResponse > div').removeClass('active')
                    $(".infoWindow .ajaxResponse > div").hide();
                    $(".infoWindow #" + div)
                        .next(".infoOverlay")
                        .show();
                    $(".infoWindow .ajaxResponse #" + div).show();
                    $(".infoWindow .ajaxResponse > #" + div).addClass("active");
                });
                $(".infoWindow .ajaxResponse").fadeIn();
            }

            if (current > 4) {
                $(".groupContainer .ajaxResponse").fadeOut(function () {
                    $(".groupContainer .ajaxResponse .active").remove();
                    //$('.groupContainer .ajaxResponse > div').removeClass('active')
                    $(".groupContainer .ajaxResponse > div").hide();
                    $(".groupContainer .ajaxResponse #" + div).show();
                    $(".groupContainer .ajaxResponse > #" + div).addClass(
                        "active"
                    );
                });
                $(".groupContainer .ajaxResponse").fadeIn();
            }

            if (current <= 2) {
                changeStageFunction("FRAMESET", "1");
            }
            if (current < 6 && current > 2) {
                changeStageFunction("COMPONENTS", "2");
            }
            if (current == 6) {
                changeStageFunction("WHEELS", "3");
            }
            if (current > 6 && current < 12) {
                changeStageFunction("GROUPSET", "4");
            }
            if (current > 11 && current < 17) {
                changeStageFunction("FINISHING TOUCHES", "5");
            }
        }

        //function to the stage

        function stageFunction(postID, stage, next, value = null) {
            $(".bikeImage .imageContainer img").addClass("moveOver");
            $(".bike-swiper-button-prev").fadeOut();
            $(".bike-swiper-button-next").fadeOut();
            $(".colourPanel").fadeOut();
            $(".colourName").fadeOut();
            $(".bikeChoices").fadeOut();
            $(".info").fadeOut();

            $(".infoWindow").addClass("open");
            $(".progressIcons .icon").addClass("white");

            //fadeout all in infoWindow
            $(".infoWindow .close").fadeOut();
            $(".infoWindow .overview").fadeOut();
            $(".infoWindow .image").fadeOut();

            $(".infoWindow .image").fadeOut("open", function () {
                $(".infoWindow .responseContainer").fadeIn();
            });

            //add previous button
            $(".prev .bikeChoices").hide();
            $(".prev .previousBtn").fadeIn();

            var action = "";

            if (stage == "brakes") {
                var action = "brakes";
            }

            if (stage == "framesize") {
                var action = "framesize";
            }

            if (stage == "barstem") {
                var action = "barstem";
            }

            if (stage == "seatpost") {
                var action = "seatpost";
            }

            $.ajax({
                type: "POST",
                url: site_data.adminajax,
                data: {
                    value: value,
                    action: action,
                    postID: postID,
                },
                dataType: "json",
                encode: true,
            })
                .done(function (response) {
                    if (response.status == "success") {
                        $(".infoWindow .ajaxResponse").fadeOut(function () {
                            $(".infoWindow .ajaxResponse > div").hide();
                            $(".infoWindow .ajaxResponse > div").removeClass(
                                "active"
                            );
                            $(".infoWindow .ajaxResponse").append(
                                response.html
                            );
                            $(
                                ".infoWindow .ajaxResponse > #" + action
                            ).addClass("active");

                            if (next >= 2 && next < 5) {
                                changeStageFunction("COMPONENTS", "2");
                            }
                        });
                        $(".infoWindow .ajaxResponse").fadeIn();
                        $(".nextButton").removeClass("disabled");
                    }
                })
                .fail(function () {})
                .always(function () {});
        }

        $("body").on("click", ".breadcrumbSlideWheel", function (event) {
            var $this = $(this);

            var classname = $this.attr("data-control");

            $(".wheel-wrapper .swiper-wrapper").removeClass("active");

            $('.wheel-wrapper:not(".wheel-wrapper-' + classname + '")').fadeOut(
                "slow",
                function () {
                    $(".wheel-wrapper-" + classname).fadeIn("slow");
                    $(
                        ".wheel-wrapper-" + classname + " .swiper-wrapper"
                    ).addClass("active");
                }
            );

            $(
                '.wheel-thumb-wrapper:not(".wheel-thumb-wrapper-' +
                    classname +
                    '")'
            ).hide();
            $(
                '.thumbSlider-button-next:not(".thumbSlider-button-next-' +
                    classname +
                    '")'
            ).hide();
            $(
                '.thumbSlider-button-prev:not(".thumbSlider-button-prev-' +
                    classname +
                    '")'
            ).hide();

            $(".wheel-thumb-wrapper-" + classname).fadeIn("slow");
            $(".thumbSlider-button-next-" + classname).fadeIn("slow");
            $(".thumbSlider-button-prev-" + classname).fadeIn("slow");

            $(".breadcrumbSlideWheel").removeClass("active");
            $this.addClass("active");
        });
    }

    function newWheelsFunction(
        postID,
        type = null,
        brakes = null,
        selectedWheel = null
    ) {
        var action = "newwheels";

        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: action,
                postID: postID,
                type: type,
                brakes: brakes,
                selectedWheel: selectedWheel,
            },
            dataType: "json",
            encode: true,
        })
            .done(function (response) {
                if (response.status == "success") {
                    //add tab selection
                    if (type == "all") {
                        $("#breadNav").html(response.options);
                    }

                    $.each(response.tabnames, function (index, name) {
                        $.each(response.html[name], function (key, val) {
                            wheelswiper[index].appendSlide(val);
                            wheelThumbSwiper[index].appendSlide(val);
                        });
                    });
                }
            })
            .fail(function () {})
            .always(function () {});
    }

    function fullwidthStageFunction(
        postID,
        stage,
        next,
        groupset = null,
        value = null
    ) {
        //fadeout all in infoWindow
        $(".groupContainer .ajaxResponse").fadeOut();

        $.ajax({
            type: "POST",
            url: site_data.adminajax,
            data: {
                action: "groupset",
                postID: postID,
                stage: stage,
                groupset: groupset,
                value: value,
            },
            dataType: "json",
            encode: true,
        })
            .done(function (response) {
                if (response.status == "success") {
                    $(".groupContainer .ajaxResponse").fadeOut(function () {
                        $(".groupContainer .ajaxResponse > div").hide();
                        $(".groupContainer .ajaxResponse > div").removeClass(
                            "active"
                        );
                        $(".groupContainer .ajaxResponse").append(
                            response.html
                        );
                        $(".groupContainer .ajaxResponse > #" + stage).addClass(
                            "active"
                        );
                    });
                    $(".groupContainer .ajaxResponse").fadeIn();
                    $(".nextButton").removeClass("disabled");

                    if (next > 5 && next < 11) {
                        changeStageFunction("GROUPSET", "4");
                    }
                    if (next > 10) {
                        changeStageFunction("FINISHING TOUCHES", "5");
                    }
                    if (next > 15) {
                        changeStageFunction("DELIVERY", "6");
                    }
                }
            })
            .fail(function () {})
            .always(function () {});
    }

    function changeStageFunction(name, number) {
        $(".stageName").html(
            '<span class="stageNumber"><p>' +
                number +
                "</p></span><h2>" +
                name +
                "</h2>"
        );
        $(".progressIcons .icon").removeClass("activeIcon");

        $(".progressIcons .icon").each(function (index, value) {
            if ($(this).attr("data-stage") == number) {
                $(this).addClass("activeIcon");
            }
        });
    }
});
